.quotes-section {
  padding: 2rem;
  margin-top: 2rem;
  background-color: #f8f9fa;
}

.quotes-section h2 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.quotes-grid {
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 0.8rem;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: nowrap;
}

.quote-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  flex: 1;
  min-width: 0;
  max-width: calc(33.333% - 1.33rem);
}

.quote-card:hover {
  transform: translateY(-5px);
}

.quote-content {
  padding: 0.4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.quote-text {
  font-size: 0.9rem;
  color: #2c3e50;
  font-style: italic;
  margin-bottom: 2rem;
  line-height: 1.3;
  flex-grow: 1;
  overflow-wrap: break-word;
}

.quote-author {
  font-weight: bold;
  color: #34495e;
  margin-bottom: 0.3rem;
}

.quote-date {
  color: #7f8c8d;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .quotes-grid {
    flex-direction: column;
    align-items: stretch;
  }
  
  .quote-card {
    max-width: 100%;
  }
} 